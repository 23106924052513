<template>
  <div class="create-course">
    <div class="hero is-info">
      <div class="columns py-3 px-3">
        <div class="column">
          <button class="button is-small is-responsive" @click="goBack">
            <i class="fas fa-arrow-left icon-spaced"></i>Back
          </button>
        </div>
        <div class="column"></div>
        <div class="column"></div>
      </div>
      <div class="has-text-centered mb-2">
        <h1 class="title is-4">Create Course</h1>
      </div>
    </div>

    <div class="columns">
      <div class="column"></div>
      <div class="column is-10">
        <section class="section">
          <div class="px-6 py-4 has-background-white-ter">
            <div class="field">
              <label class="label">Title</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder="Title"
                  v-model="form.title"
                />
              </div>
              <div class="field">
                <label class="label">Short description</label>
                <div class="control">
                  <textarea
                    class="textarea"
                    placeholder="Short description"
                    v-model="form.short_description"
                  ></textarea>
                </div>
              </div>
              <div class="field">
                <label class="label">Long description</label>
                <div class="control">
                  <textarea
                    class="textarea"
                    placeholder="Long description"
                    v-model="form.long_description"
                  ></textarea>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <label class="label">Choose Learning Path</label>
                        <div class="select is-primary">
                          <select v-model="form.learning_path">
                            <option value="">Choose Learning Path</option>
                            <option
                              v-for="path in learningPaths"
                              :key="path.id"
                              :value="path.id"
                            >
                              {{ path.title }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field mt-3">
                        <label class="label">Add Learning Path</label>
                        <button
                          class="button is-secondary"
                          @click="openLearningPathModal"
                        >
                          <span class="icon">
                            <i class="fas fa-plus"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <label class="label">Choose category</label>
                        <div class="select is-multiple">
                          <select multiple size="3" v-model="form.categories">
                            <option
                              v-for="category in categories"
                              v-bind:key="category.id"
                              v-bind:value="category.id"
                            >
                              {{ category.title }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field mt-3">
                        <label class="label">Add category</label>
                        <button
                          class="button is-secondary"
                          @click="openCategoryModal"
                        >
                          <span class="icon">
                            <i class="fas fa-plus"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="file has-name">
                <label class="file-label">
                  <input
                    class="file-input"
                    type="file"
                    name="image"
                    @change="handleFileUpload"
                  />
                  <span class="file-cta">
                    <span class="file-icon">
                      <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label"> Course image… </span>
                  </span>
                  <span class="file-name" v-if="form.image">
                    {{ form.image.name }}
                  </span>
                </label>
              </div>
            </div>
            <div class="field is-grouped">
              <div class="control">
                <button class="button is-success" @click="submitForm('draft')">
                  <i class="fa fa-check icon-spaced"></i>
                  Save as draft
                </button>
              </div>
              <div class="control">
                <button class="button is-info" @click="submitForm('in_review')">
                  <i class="fa fa-check icon-spaced"></i>
                  Submit for review
                </button>
              </div>
              <div class="control">
                <button class="button is-link" @click="submitForm('published')">
                  <i class="fa fa-check icon-spaced"></i>
                  Publish
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <CategoryModal ref="categoryModal" @category-added="updateCategories" />
    <LearningPathModal
      ref="LearningPathModal"
      @learning-path-added="updateLearningPaths"
    />
  </div>
</template>

<script>
import axios from "axios";
import CategoryModal from "@/components/Categories/CategoryModal.vue";
import LearningPathModal from "@/components/LearningPath/LearningPathModal.vue";

export default {
  data() {
    return {
      form: {
        title: "",
        short_description: "",
        long_description: "",
        categories: [],
        learning_path: "",
        status: "",
        image: null,
      },
      categories: [],
      learningPaths: [],
    };
  },
  components: {
    CategoryModal,
    LearningPathModal,
  },
  mounted() {
    this.getCategories();
    this.getLearningPaths();
  },
  methods: {
    async getCategories() {
      // console.log("categories");
      await axios.get("courses/categories/").then((response) => {
        // console.log(response.data);
        this.categories = response.data.data;
      });
    },
    async getLearningPaths() {
      try {
        const response = await axios.get("courses/learning-paths/");
        this.learningPaths = response.data.data;
      } catch (error) {
        console.error("Error fetching learning paths:", error);
      }
    },
    goBack() {
      this.$router.back();
    },
    handleFileUpload(event) {
      this.form.image = event.target.files[0];
    },
    async submitForm(status) {
      // console.log("submit form");

      // console.log(this.form);

      this.form.status = status;

      await axios
        .post("courses/", this.form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data);
          const slug = response.data.data.slug;
          this.$router.push({ name: "CreateModule", params: { slug } });
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    openCategoryModal() {
      this.$refs.categoryModal.openModal();
    },
    openLearningPathModal() {
      this.$refs.LearningPathModal.openModal();
    },
    async updateCategories(category) {
      await this.getCategories();
      if (!this.form.categories.includes(category.id)) {
        this.form.categories.push(category.id);
      }
    },
  },
};
</script>
<style scoped>
.icon-spaced {
  margin-right: 8px;
}
</style>
