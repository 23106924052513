<template>
  <div>
    <h1>Teacher Notifications</h1>
    <!-- <CalenderComponent /> -->
  </div>
</template>

<script>
import CalenderComponent from "@/components/Utils/CalenderComponent";

export default {
  components: {
    CalenderComponent,
  },
};
</script>
